import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPostCard from "../components/blog-post-card"
import { rhythm } from "../utils/typography"
import "../css/typography.css"

const CategoryPostsTemplate = ({ data, location }) => {
  const category = data.contentfulCategory
  const posts = category.post
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={`Posts about ${category.title}`} />
      <h1
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(1 / 2),
          color: "#3e5336",
          fontFamily: "Baloo Bhaina 2",
        }}
      >
        All posts: {category.title}
      </h1>
      <div style={{ backgroundColor: "#e9dcd1" }}>
        {posts &&
          posts.map(node => {
            return <BlogPostCard post={node} />
          })}
      </div>
    </Layout>
  )
}

export default CategoryPostsTemplate

export const pageQuery = graphql`
  query BlogPostsByCategory($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      title
      post {
        title
        subtitle
        slug
        date
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
