import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rhythm } from "../utils/typography"
import "../css/typography.css"

const Post = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const PostImg = styled.div`
  flex: 25%;
  margin-right: 1rem;
`

const PostText = styled.div`
  color: #3e5336;
  flex: 75%;
  font-family: "Baloo Bhaina 2";
`

const BlogPostCard = ({
  post: { title, subtitle, slug, image, date, category },
}) => {
  const postTitle = title || slug
  return (
    <Post key={slug}>
      {image && (
        <PostImg>
          <Img fluid={image.fluid} />
        </PostImg>
      )}
      <PostText>
        <header>
          <h3
            style={{
              marginTop: 0,
              marginBottom: rhythm(1 / 4),
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                color: "#3e5336",
                fontFamily: "Girassol",
              }}
              to={slug}
            >
              {postTitle}
            </Link>
          </h3>
          <small>
            {category &&
              category.map(c => (
                <Link
                  style={{
                    boxShadow: `none`,
                    border: "1px solid #948f47",
                    borderRadius: "10px",
                    backgroundColor: "#948f47",
                    color: "#ffffff",
                    display: "inline-block",
                    padding: "2px 5px 0",
                    textAlign: "center",
                    marginRight: "4px",
                  }}
                  to={`/category/${c.slug}`}
                >
                  <div
                    style={{
                      fontFamily: "Baloo Bhaina 2",
                    }}
                  >
                    {c.title}
                  </div>
                </Link>
              ))}
            <div
              style={{
                color: "#948f47",
                fontFamily: "Baloo Bhaina 2",
              }}
            >
              {date}
            </div>
          </small>
        </header>
        <section
          style={{
            fontFamily: "Baloo Bhaina 2",
          }}
        >
          <p>{subtitle}</p>
        </section>
      </PostText>
    </Post>
  )
}

export default BlogPostCard
